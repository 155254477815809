import React, { FC } from 'react'
import ReactDisqusComments from 'react-disqus-comments'
import { useSiteMetadata } from '../../../hooks'

interface CommentsI {
  postTitle: string
  postSlug: string
}

const Comments: FC<CommentsI> = ({ postTitle, postSlug }) => {
  const { url, disqusShortname } = useSiteMetadata()

  if (!disqusShortname) {
    return null
  }

  return (
    <ReactDisqusComments shortname={disqusShortname} identifier={postTitle} title={postTitle} url={url + postSlug} />
  )
}

export default Comments
